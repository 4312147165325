
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import axios from "axios";

export default defineComponent({
  name: "sign-up",
  components: {},
  setup() {
    const router = useRouter();
    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);

    const defaultData = {
      firstName: "",
      lastName: "",
      username: "",
      password: "",
      confirmPassword: "",
      gender: "male",
      birthday: "2021-01-01",
    };

    const formData = ref({ ...defaultData });

    const labelGenders = [
      {
        value: "male",
        label: "Nam",
      },
      {
        value: "female",
        label: "Nữ",
      },
    ];

    const validateEmail = (email) => {
      return email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    };

    const validateVietnamPhone = (str) => {
      const regex = /(84|0[3|5|7|8|9])+([0-9]{8})\b/gm;
      let m;
      let isMatching = false;
      while ((m = regex.exec(str)) !== null) {
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        }
        m.forEach((match, groupIndex) => {
          isMatching = true;
        });
      }
      return isMatching;
    };

    var validateUsername = (rule, value, callback) => {
      if (validateEmail(value) || validateVietnamPhone(value)) {
        callback();
      } else {
        callback(
          new Error("Username cần có định dạng email hoặc số điện thoại!")
        );
      }
    };

    var validateConfirmPassword = (rule, value, callback) => {
      if (formData.value.password === value) {
        callback();
      } else {
        callback(new Error("Mật khẩu nhập lại chưa trùng!"));
      }
    };

    const rules = ref({
      firstName: [
        { required: true, message: "Bạn cần điền tên.", trigger: "blur" },
        {
          min: 2,
          max: 30,
          message: "Độ dài từ 2 đến 30 ký tự",
          trigger: "blur",
        },
      ],
      username: [
        { required: true, message: "Bạn cần điền tên.", trigger: "blur" },
        {
          min: 2,
          max: 30,
          message: "Độ dài từ 2 đến 30 ký tự",
          trigger: "blur",
        },
        { validator: validateUsername, trigger: "blur" },
      ],
      password: [
        {
          required: true,
          message: "Yêu cầu nhập mật khẩu.",
          trigger: "blur",
        },
        {
          min: 6,
          max: 30,
          message: "Mật khẩu dài từ 6 đến 30 ký tự",
          trigger: "blur",
        },
      ],
      confirmPassword: [
        {
          required: true,
          message: "Yêu cầu nhập lại mật khẩu.",
          trigger: "blur",
        },
        { validator: validateConfirmPassword, trigger: "blur" },
      ],
      birthday: [
        {
          required: true,
          message: "Yêu cầu nhập ngày sinh.",
          trigger: "blur",
        },
      ],
    });

    const urlRegister = "https://vm-oauth.daybreak.icu/api/register";
    const actionRegister = (params) => {
      params.app_id = "U8TcMmc8cxnbRk3Y";
      axios
        .post(urlRegister, params, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.status === "success") {
            Swal.fire({
              text: "Đăng ký thành công",
              icon: "success",
              buttonsStyling: false,
              timer: 2000,
              width: 400,
              showCancelButton: false,
              showConfirmButton: false,
            }).then(function () {
              // Go to page after successfully login
              router.push({ name: "sign-in" });
            });
          } else {
            Swal.fire({
              text: response.data.message,
              icon: "error",
              buttonsStyling: false,
              timer: 3000,
              width: 400,
              showCancelButton: false,
              showConfirmButton: false,
            });
          }
        });
    };

    const submit = async () => {
      console.log("submit");
      console.log(formRef.value);
      if (!formRef.value) {
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          loading.value = true;
          console.log(formData.value);
          await actionRegister(formData.value);
        } else {
          Swal.fire({
            text: "Định dạng dữ liệu không đúng, vui lòng kiểm tra lại biểu mẫu",
            icon: "error",
            buttonsStyling: false,
            timer: 2000,
            width: 400,
            showCancelButton: false,
            showConfirmButton: false,
          });
          return false;
        }
      });
    };
    return {
      defaultData,
      labelGenders,
      formData,
      rules,
      submit,
      formRef,
    };
  },
});
